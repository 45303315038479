class SyncManager extends EventTarget {
    #Events = new Set([

    ]);

    constructor() {
        super();
    }

    async startSync(syncOptions) {
        console.log('Starting sync from sync manager');

        return {};
    }
}

export default new SyncManager();
